import GeoJSON from 'ol/format/GeoJSON.js';
import {Vector as VectorLayer, Group as LayerGroup} from 'ol/layer.js';
import {Vector as VectorSource} from 'ol/source.js';
import {Fill, Stroke, Style} from 'ol/style.js';



////////////////////////////////////////////////////////////////////////
//Función para capa de ENP

var GeoJSON_ENP_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_ENP.features.length; i++){
  var feature =GeoJSON_ENP.features[i];

  if (feature.properties["FECHA_APRO"] !== null || feature.properties["SITE_NAME"] == 'Doramas') {
    GeoJSON_ENP_Filter.features.push(feature);
  }
};

var vectorSourceENP_DT = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_ENP_Filter)
});


global.styleFunction_ENP_DT = function(feature, resolution) {
  var estilo;

  if(feature.getProperties().FECHA_APRO != null){
    estilo = new  Style({
      stroke: Funct_StrokeStyle[feature.getProperties().NOM_COMPLE],
      fill: Funct_FillStyle[feature.getProperties().NOM_COMPLE],
      text: createTextStyle(feature, resolution, 'none', feature.get("Tipo") + " " + feature.get("SITE_NAME") +  "\nAprobado en: " + feature.get("FECHA_APRO"), "Arial", 0.9, 1, "rgba(0, 0, 0, 1)", 1, 'rgba(255, 255, 255, 1)', 2, 1, 'rgba(255, 255, 255, 1)', "rgba(0, 0, 0, 0.75)", 0.2, [2,2,2,2], 0, 0),
      zIndex: Funct_ZIndexStyle[feature.getProperties().NOM_COMPLE]
    });
  }else if(feature.getProperties().SITE_NAME=='Doramas'){
    estilo = new  Style({
      stroke: Funct_StrokeStyle[feature.getProperties().NOM_COMPLE],
      fill: Funct_FillStyle[feature.getProperties().NOM_COMPLE],
      text: createTextStyle(feature, resolution, 'none', feature.get("Tipo") + " " + feature.get("SITE_NAME"), "Arial", 0.9, 1, "rgba(0, 0, 0, 1)", 1, 'rgba(255, 255, 255, 1)', 2, 1, 'rgba(255, 255, 255, 1)', "rgba(0, 0, 0, 0.75)", 0.2, [2,2,2,2], 0, 0),
      zIndex: Funct_ZIndexStyle[feature.getProperties().NOM_COMPLE]
    });
  }

  return estilo
};


global.styleFunction_ENP_DT_filter = function(feature, resolution) {
  var estilo;

  if(feature.getProperties().FECHA_APRO != null){
    estilo = new  Style({
      stroke: Funct_StrokeStyle[feature.getProperties().NOM_COMPLE + " CONTORNO"],
      fill: Funct_FillStyle[feature.getProperties().NOM_COMPLE + " CONTORNO"],
      text: createTextStyle(feature, resolution, 'none', feature.get("Tipo") + " " + feature.get("SITE_NAME") +  "\nAprobado en: " + feature.get("FECHA_APRO"), "Arial", 0.9, 1, "rgba(0, 0, 0, 1)", 1, 'rgba(255, 255, 255, 1)', 2, 1, 'rgba(255, 255, 255, 1)', "rgba(0, 0, 0, 0.75)", 0.2, [2,2,2,2], 0, 0),
      zIndex: Funct_ZIndexStyle[feature.getProperties().NOM_COMPLE]
    });
  }else if(feature.getProperties().SITE_NAME=='Doramas'){
    estilo = new  Style({
      stroke: Funct_StrokeStyle[feature.getProperties().NOM_COMPLE+ " CONTORNO"],
      fill: Funct_FillStyle[feature.getProperties().NOM_COMPLE+ " CONTORNO"],
      text: createTextStyle(feature, resolution, 'none', feature.get("Tipo") + " " + feature.get("SITE_NAME"), "Arial", 0.9, 1, "rgba(0, 0, 0, 1)", 1, 'rgba(255, 255, 255, 1)', 2, 1, 'rgba(255, 255, 255, 1)', "rgba(0, 0, 0, 0.75)", 0.2, [2,2,2,2], 0, 0),
      zIndex: Funct_ZIndexStyle[feature.getProperties().NOM_COMPLE]
    });
  }

  return estilo
};

global.Layer_OE_ENP1 = new VectorLayer({
  title: 'Layer_OE_ENP1',
  name: '01 Espacio Natural Protegido',
  type: 'Capa específica',
  source: vectorSourceENP_DT,
  style: styleFunction_ENP_DT,
  zIndex:8
});

global.Layer_OE_ENP2 = new VectorLayer({
  title: 'Layer_OE_ENP2',
  name: '01 Espacio Natural Protegido',
  type: 'Capa específica',
  source: vectorSourceENP_DT,
  style: styleFunction_ENP_DT,
  zIndex:8
});

global.Layer_OE_ENP3 = new VectorLayer({
  title: 'Layer_OE_ENP3',
  name: '01 Espacio Natural Protegido',
  type: 'Capa específica',
  source: vectorSourceENP_DT,
  style: styleFunction_ENP_DT,
  zIndex:8
});

global.Layer_OE_ENP4 = new VectorLayer({
  title: 'Layer_OE_ENP4',
  name: '01 Espacio Natural Protegido',
  type: 'Capa específica',
  source: vectorSourceENP_DT,
  style: styleFunction_ENP_DT,
  zIndex:8
});

global.Layer_OE_ENP5 = new VectorLayer({
  title: 'Layer_OE_ENP5',
  name: '01 Espacio Natural Protegido',
  type: 'Capa específica',
  source: vectorSourceENP_DT,
  style: styleFunction_ENP_DT_filter,
  zIndex:8
});

global.Layer_OE_ENP6 = new VectorLayer({
  title: 'Layer_OE_ENP6',
  name: '01 Espacio Natural Protegido',
  type: 'Capa específica',
  source: vectorSourceENP_DT,
  style: styleFunction_ENP_DT,
  zIndex:8
});

global.Layer_OE_ENP7 = new VectorLayer({
  title: 'Layer_OE_ENP7',
  name: '01 Espacio Natural Protegido',
  type: 'Capa específica',
  source: vectorSourceENP_DT,
  style: styleFunction_ENP_DT,
  zIndex:8
});

global.Layer_OE_ENP8 = new VectorLayer({
  title: 'Layer_OE_ENP8',
  name: '01 Espacio Natural Protegido',
  type: 'Capa específica',
  source: vectorSourceENP_DT,
  style: styleFunction_ENP_DT,
  zIndex:8
});

global.Layer_OE_ENP9 = new VectorLayer({
  title: 'Layer_OE_ENP9',
  name: '01 Espacio Natural Protegido',
  type: 'Capa específica',
  source: vectorSourceENP_DT,
  style: styleFunction_ENP_DT,
  zIndex:8
});

global.Layer_OE_ENP10 = new VectorLayer({
  title: 'Layer_OE_ENP10',
  name: '01 Espacio Natural Protegido',
  type: 'Capa específica',
  source: vectorSourceENP_DT,
  style: styleFunction_ENP_DT,
  zIndex:8
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE SISTEMA TERRITORIAL

var GeoJSON_O_Red_Viaria_Eje_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_Red_Viaria_Eje.features.length; i++){
  var feature =GeoJSON_O_Red_Viaria_Eje.features[i];

  if (feature.properties["JERARQUIA"] == 'Red principal' || feature.properties["JERARQUIA"] == 'Red complementaria' || feature.properties["JERARQUIA"] == 'Red local') {
    GeoJSON_O_Red_Viaria_Eje_Filter.features.push(feature);
  }
};

var vectorSource_OE_Red_viaria = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_Red_Viaria_Eje_Filter)
});

var styleFunction_OE_Red_viaria= function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().JERARQUIA + " OE"],
    fill: Funct_FillStyle[feature.getProperties().JERARQUIA+ " OE"]
  })
};

global.Layer_OE_Red_viaria = new VectorLayer({
  title: 'Layer_OE_Red_viaria',
  name: '03 Red viaria',
  type: 'Capa específica',
  source: vectorSource_OE_Red_viaria,
  style: styleFunction_OE_Red_viaria,
  zIndex:8
});

var GeoJSON_O_MODORD_Filter2 = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_MODORD.features.length; i++){
  var feature =GeoJSON_O_MODORD.features[i];

  if (feature.properties["USOS_GLOBA"] =='Residencial' ) {
    GeoJSON_O_MODORD_Filter2.features.push(feature);
  }
};

var vectorSource_OE_AreasUrbanas = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_MODORD_Filter2)
});

var styleFunction_OE_AreasUrbanas = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_OE_AreasUrbanas],
    fill: Funct_FillStyle[Symbolyze_Layer_OE_AreasUrbanas],
    zIndex: Funct_ZIndexStyle[Symbolyze_Layer_OE_AreasUrbanas]
  })
};

global.Layer_OE_AreasUrbanas = new VectorLayer({
  title: 'Layer_OE_AreasUrbanas',
  name:  'F02 ' + Symbolyze_Layer_OE_AreasUrbanas,
  type: 'Capa específica',
  source: vectorSource_OE_AreasUrbanas,
  style: styleFunction_OE_AreasUrbanas,
  zIndex:8
});

var GeoJSON_O_MODORD_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_MODORD.features.length; i++){
  var feature =GeoJSON_O_MODORD.features[i];

  if (feature.properties["USOS_GLOBA"] !==null ) {
    GeoJSON_O_MODORD_Filter.features.push(feature);
  }
};

var vectorSource_OE_Usos_globales = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_MODORD_Filter)
});

var styleFunction_OE_Usos_globales = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().USOS_GLOBA],
    fill: Funct_FillStyle[feature.getProperties().USOS_GLOBA],
    zIndex: Funct_ZIndexStyle[feature.getProperties().USOS_GLOBA]
  })
};

global.Layer_OE_Usos_globales = new VectorLayer({
  title: 'Layer_OE_Usos_globales',
  name: '04 Usos globales',
  type: 'Capa específica',
  source: vectorSource_OE_Usos_globales,
  style: styleFunction_OE_Usos_globales,
  zIndex:3
});

var GeoJSON_OE_SG_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_OE_SG.features.length; i++){
  var feature =GeoJSON_OE_SG.features[i];

  if (feature.properties["USO_GLOBAL"] !==null ) {
    GeoJSON_OE_SG_Filter.features.push(feature);
  }
};

var vectorSource_OE_Usos_globales_SG = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_OE_SG_Filter)
});

var styleFunction_OE_Usos_globales_SG = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().USO_GLOBAL],
    fill: Funct_FillStyle[feature.getProperties().USO_GLOBAL],
    zIndex: Funct_ZIndexStyle[feature.getProperties().USO_GLOBAL]
  })
};

global.Layer_OE_Usos_globales_SG = new VectorLayer({
  title: 'Layer_OE_Usos_globales_SG',
  name: '04 Usos globales',
  type: 'Capa específica',
  source: vectorSource_OE_Usos_globales_SG,
  style: styleFunction_OE_Usos_globales_SG,
  zIndex:3
});

var GeoJSON_OE_SG_Filter2 = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_OE_SG.features.length; i++){
  var feature =GeoJSON_OE_SG.features[i];

  if (feature.properties["TIPO"] =='Sistema General' ) {
    GeoJSON_OE_SG_Filter2.features.push(feature);
  }
};

var vectorSource_OE_SG = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_OE_SG_Filter2)
});

var styleFunction_OE_SG = function(feature, resolution) {
  var ValorSimbolizacion = Leyenda_OE_SG[feature.getProperties().TIPO_DES][1];
  var ParametrosLabel = Funct_Label["Layer_OE_SG"];

  return new  Style({
    stroke: Funct_StrokeStyle[ValorSimbolizacion],
    fill: Funct_FillStyle[ValorSimbolizacion],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

global.Layer_OE_SG = new VectorLayer({
  title: 'Layer_OE_SG',
  name: '04 Sistemas Generales',
  type: 'Capa específica',
  source: vectorSource_OE_SG,
  style: styleFunction_OE_SG,
  zIndex:9
});

var GeoJSON_OE_SG_Filter3 = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_OE_SG.features.length; i++){
  var feature =GeoJSON_OE_SG.features[i];

  if (feature.properties["TIPO"] =='Equipamiento Estructurante' ) {
    GeoJSON_OE_SG_Filter3.features.push(feature);
  }
};

var vectorSource_OE_EE = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_OE_SG_Filter3)
});

var styleFunction_OE_EE = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_OE_SG"];

  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().TIPO],
    fill: Funct_FillStyle[feature.getProperties().TIPO],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

global.Layer_OE_EE = new VectorLayer({
  title: 'Layer_OE_EE',
  name: '04 Sistemas Generales',
  type: 'Capa específica',
  source: vectorSource_OE_EE,
  style: styleFunction_OE_EE,
  zIndex:9
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_OE_00 = new LayerGroup({
  title:    'Map_OE_00',
  name:     'Sistema Territorial',
  type:     'Capa específica',
  opacity:  0.75,
	visible:   false,
  layers:[
    Layer_OE_ENP1,
    Layer_OE_AreasUrbanas,
    Layer_OE_Red_viaria,
    Layer_OE_Usos_globales,
    Layer_OE_Usos_globales_SG,
    Layer_OE_SG,
    Layer_OE_EE
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE USOS GLOBALES
var GeoJSON_O_MODORD_Filter2 = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_MODORD.features.length; i++){
  var feature =GeoJSON_O_MODORD.features[i];

  if (feature.properties["USOS_GLOBA"] !==null ) {
    GeoJSON_O_MODORD_Filter2.features.push(feature);
  }
};

var vectorSource_OE_Usos_globales2 = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_MODORD_Filter2)
});

var styleFunction_OE_Usos_globales = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().USOS_GLOBA],
    fill: Funct_FillStyle[feature.getProperties().USOS_GLOBA],
    zIndex: Funct_ZIndexStyle[feature.getProperties().USOS_GLOBA]
  })
};

global.Layer_OE_Usos_globales2 = new VectorLayer({
  title: 'Layer_OE_Usos_globales2',
  name: '02 Usos globales',
  type: 'Capa específica',
  source: vectorSource_OE_Usos_globales2,
  style: styleFunction_OE_Usos_globales,
  zIndex:3
});

var GeoJSON_OE_SG_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_OE_SG.features.length; i++){
  var feature =GeoJSON_OE_SG.features[i];

  if (feature.properties["USO_GLOBAL"] =='Recreativo' ) {
    GeoJSON_OE_SG_Filter.features.push(feature);
  }
};

var vectorSource_OE_Usos_globales_SG2 = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_OE_SG_Filter)
});

var styleFunction_OE_Usos_globales_SG = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().USO_GLOBAL],
    fill: Funct_FillStyle[feature.getProperties().USO_GLOBAL],
    zIndex: Funct_ZIndexStyle[feature.getProperties().USO_GLOBAL]
  })
};

global.Layer_OE_Usos_globales_SG2 = new VectorLayer({
  title: 'Layer_OE_Usos_globales_SG2',
  name: '02 Usos globales',
  type: 'Capa específica',
  source: vectorSource_OE_Usos_globales_SG2,
  style: styleFunction_OE_Usos_globales_SG,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_OE_01 = new LayerGroup({
  title:    'Map_OE_01',
  name:     'Usos globales',
  type:     'Capa específica',
  opacity:  0.75,
	visible:   false,
  layers:[
    Layer_OE_ENP2,
    Layer_OE_Usos_globales2,
    Layer_OE_Usos_globales_SG2
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE CLASIFICACIÓN

var GeoJSON_O_MODORD_Filter3 = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_MODORD.features.length; i++){
  var feature =GeoJSON_O_MODORD.features[i];

  if (feature.properties["CLA"] !=='Suelo Rústico (superpuesto)' ) {
    GeoJSON_O_MODORD_Filter3.features.push(feature);
  }
};

var vectorSource_OE_Clasificacion = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_MODORD_Filter3)
});

var styleFunction_OE_Clasificacion = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().CLA],
    fill: Funct_FillStyle[feature.getProperties().CLA]
  })
};

global.Layer_OE_Clasificacion = new VectorLayer({
  title: 'Layer_OE_Clasificacion',
  name: '02 Clasificación',
  type: 'Capa específica',
  source: vectorSource_OE_Clasificacion,
  style: styleFunction_OE_Clasificacion,
  zIndex:3
});

var GeoJSON_O_MODORD_Filter4 = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_MODORD.features.length; i++){
  var feature =GeoJSON_O_MODORD.features[i];

  if (feature.properties["CATEG"] =='Suelo Rústico de Asentamiento Rural' || feature.properties["CATEG"] =='Suelo Rústico de Asentamiento Agrícola' ) {
    GeoJSON_O_MODORD_Filter4.features.push(feature);
  }
};

var vectorSource_OE_Clasificacion2 = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_MODORD_Filter4)
});

var styleFunction_OE_Categorizacion2 = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().CATEG + " SUPERPUESTO"],
    fill: Funct_FillStyle[feature.getProperties().CATEG + " SUPERPUESTO"]
  })
};

global.Layer_OE_Clasificacion2 = new VectorLayer({
  title: 'Layer_OE_Clasificacion2',
  name: '02 Clasificación',
  type: 'Capa específica',
  source: vectorSource_OE_Clasificacion2,
  style: styleFunction_OE_Categorizacion2,
  zIndex:9
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_OE_02 = new LayerGroup({
  title:    'Map_OE_02',
  name:     'Clasificación del suelo',
  type:     'Capa específica',
  opacity:  0.75,
	visible:   false,
  layers:[
    Layer_OE_ENP3,
    Layer_OE_Clasificacion,
    Layer_OE_Clasificacion2
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE CATEGORIZACIÓN

var GeoJSON_O_MODORD_Filter5 = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_MODORD.features.length; i++){
  var feature =GeoJSON_O_MODORD.features[i];

  if (feature.properties["CATEG"].search('Suelo Urbano')!==-1) {
    GeoJSON_O_MODORD_Filter5.features.push(feature);
  }
};

var vectorSource_OE_Categorización1 = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_MODORD_Filter5)
});

var styleFunction_OE_Categorizacion = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_OE_Categorizacion"];

  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().CATEG],
    fill: Funct_FillStyle[feature.getProperties().CATEG],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15]),
    zIndex: Funct_ZIndexStyle[feature.getProperties().CATEG]
  })
};

global.Layer_OE_Categorizacion_SU = new VectorLayer({
  title: 'Layer_OE_Categorizacion_SU',
  name: '02 Suelo urbano',
  type: 'Capa específica',
  source: vectorSource_OE_Categorización1,
  style: styleFunction_OE_Categorizacion,
  zIndex:3
});

var GeoJSON_O_MODORD_Filter6 = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_MODORD.features.length; i++){
  var feature =GeoJSON_O_MODORD.features[i];

  if (feature.properties["CATEG"].search('Suelo Urbani')!==-1) {
    GeoJSON_O_MODORD_Filter6.features.push(feature);
  }
};

var vectorSource_OE_Categorización2 = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_MODORD_Filter6)
});

global.Layer_OE_Categorizacion_SUz = new VectorLayer({
  title: 'Layer_OE_Categorizacion_SUz',
  name: '03 Suelo urbanizable',
  type: 'Capa específica',
  source: vectorSource_OE_Categorización2,
  style: styleFunction_OE_Categorizacion,
  zIndex:3
});

var GeoJSON_O_MODORD_Filter7 = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_MODORD.features.length; i++){
  var feature =GeoJSON_O_MODORD.features[i];

  if (feature.properties["CATEG"].search('Suelo Rústic')!==-1 && feature.properties["CATEG"].search('ENP')==-1) {
    GeoJSON_O_MODORD_Filter7.features.push(feature);
  }
};

var vectorSource_OE_Categorización3 = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_MODORD_Filter7)
});

global.Layer_OE_Categorizacion_SR = new VectorLayer({
  title: 'Layer_OE_Categorizacion_SR',
  name: '04 Suelo rústico',
  type: 'Capa específica',
  source: vectorSource_OE_Categorización3,
  style: styleFunction_OE_Categorizacion,
  zIndex:3
});

var GeoJSON_O_MODORD_Filter8 = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_MODORD.features.length; i++){
  var feature =GeoJSON_O_MODORD.features[i];

  if (feature.properties["CATEG"].search('ENP')!==-1) {
    GeoJSON_O_MODORD_Filter8.features.push(feature);
  }
};

var vectorSource_OE_Categorización4 = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_MODORD_Filter8)
});

global.Layer_OE_Categorizacion_SR_ENP = new VectorLayer({
  title: 'Layer_OE_Categorizacion_SR_ENP',
  name: '05 Ordenación Parque Rural Doramas',
  type: 'Capa específica',
  source: vectorSource_OE_Categorización4,
  style: styleFunction_OE_Categorizacion,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_OE_03 = new LayerGroup({
  title:         'Map_OE_03',
  name:       'Categorización del suelo',
  type:         'Capa específica',
  opacity:    0.75,
	visible:     false,
  layers:[
    Layer_OE_ENP4,
    Layer_OE_Categorizacion_SU,
    Layer_OE_Categorizacion_SUz,
    Layer_OE_Categorizacion_SR,
    Layer_OE_Categorizacion_SR_ENP
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE CATEGORIZACIÓN ENP APROBADO
var vectorSource_OE_Caterorizacion_ENP = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_SITCAN_CLA_ENP)
});

var styleFunction_OE_Caterorizacion_ENP = function(feature, resolution) {
  var ValorSimbolizacion = Leyenda_CATEGORIZACION_ENP[feature.getProperties().TXTPLAN][1];

  return new  Style({
    stroke: Funct_StrokeStyle[ValorSimbolizacion],
    fill: Funct_FillStyle[ValorSimbolizacion]
  })
};

global.Layer_OE_Categorizacion_ENP = new VectorLayer({
  title: 'Layer_OE_Categorizacion_ENP',
  name: '02 Clasificación y categorización del suelo',
  type: 'Capa específica',
  source: vectorSource_OE_Caterorizacion_ENP,
  style: styleFunction_OE_Caterorizacion_ENP,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_OE_04 = new LayerGroup({
  title:         'Map_OE_04',
  name:       'Categorización del suelo. ENP con planeamiento aprobado',
  type:         'Capa específica',
  opacity:    0.75,
	visible:     false,
  layers:[
    Layer_OE_ENP5,
    Layer_OE_Categorizacion_ENP
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE RED VIARIA Y SG
var GeoJSON_O_MODORD_Filter_INFRA = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_MODORD.features.length; i++){
  var feature =GeoJSON_O_MODORD.features[i];

  if (feature.properties["CATEG"].search('Suelo Urban')!==-1 || feature.properties["CATEG"] =='Suelo Rústico de Asentamiento Rural' || feature.properties["CATEG"] =='Suelo Rústico de Asentamiento Agrícola' ) {
    GeoJSON_O_MODORD_Filter_INFRA.features.push(feature);
  }
};

var vectorSource_OE_Categorización_INFRA = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_MODORD_Filter_INFRA)
});

global.Layer_OE_Categorizacion_INFRA1 = new VectorLayer({
  title: 'Layer_OE_Categorizacion_INFRA1',
  name: '02 Clasificación y categorización del suelo',
  type: 'Capa específica',
  source: vectorSource_OE_Categorización_INFRA,
  style: styleFunction_OE_Categorizacion,
  zIndex:3
});

var GeoJSON_OE_SG_Filter_INFRA = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_OE_SG.features.length; i++){
  var feature =GeoJSON_OE_SG.features[i];

  if (feature.properties["TIPO"] =='Sistema General' ) {
    GeoJSON_OE_SG_Filter_INFRA.features.push(feature);
  }
};

var vectorSource_OE_SG_INFRA = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_OE_SG_Filter_INFRA)
});

var styleFunction_OE_SG_INFRA = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_OE_SG"];

  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().ESTADO_ACT],
    fill: Funct_FillStyle[feature.getProperties().ESTADO_ACT],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

global.Layer_OE_SG_INFRA1 = new VectorLayer({
  title: 'Layer_OE_SG_INFRA1',
  name: '04 Sistemas Generales',
  type: 'Capa específica',
  source: vectorSource_OE_SG_INFRA,
  style: styleFunction_OE_SG_INFRA,
  zIndex:9
});

var GeoJSON_OE_EE_INFRA = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_OE_SG.features.length; i++){
  var feature =GeoJSON_OE_SG.features[i];

  if (feature.properties["TIPO"] =='Equipamiento Estructurante' ) {
    GeoJSON_OE_EE_INFRA.features.push(feature);
  }
};

var vectorSource_OE_EE_INFRA = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_OE_EE_INFRA)
});

var styleFunction_OE_EE_INFRA = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_OE_SG"];

  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().ESTADO_ACT + ' EE'],
    fill: Funct_FillStyle[feature.getProperties().ESTADO_ACT + ' EE'],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

global.Layer_OE_EE_INFRA1 = new VectorLayer({
  title: 'Layer_OE_EE_INFRA1',
  name: '05 Equipamientos Estructurantes',
  type: 'Capa específica',
  source: vectorSource_OE_EE_INFRA,
  style: styleFunction_OE_EE_INFRA,
  zIndex:9
});

var GeoJSON_O_Red_Viaria_Eje_Null = FiltrarNulosGeoJson(GeoJSON_O_Red_Viaria_Eje, "JERARQUIA");

var vectorSource_OE_Red_viaria_INFRA = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_Red_Viaria_Eje_Null)
});

var styleFunction_OE_Red_viaria_INFRA= function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().JERARQUIA]
  })
};

global.Layer_OE_Red_viaria_INFRA = new VectorLayer({
  title: 'Layer_OE_Red_viaria_INFRA',
  name: '09 Red viaria',
  type: 'Capa específica',
  source: vectorSource_OE_Red_viaria_INFRA,
  style: styleFunction_OE_Red_viaria_INFRA,
  zIndex:8
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_OE_05a = new LayerGroup({
  title:         'Map_OE_05a',
  name:       'Red viaria y Sistemas Generales',
  type:         'Capa específica',
  opacity:    0.75,
	visible:     false,
  layers:[
    Layer_OE_ENP6,
    Layer_OE_Categorizacion_INFRA1,
    Layer_OE_SG_INFRA1,
    Layer_OE_EE_INFRA1,
    Layer_OE_Red_viaria_INFRA
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE INFRAESTRUCTURA ABASTECIMIENTO

global.Layer_OE_Categorizacion_INFRA2 = new VectorLayer({
  title: 'Layer_OE_Categorizacion_INFRA2',
  name: '02 Clasificación y categorización del suelo',
  type: 'Capa específica',
  source: vectorSource_OE_Categorización_INFRA,
  style: styleFunction_OE_Categorizacion,
  zIndex:3
});

var styleFunction_O_abast_point = function(feature, resolution) {
  return new  Style({
    image: Funct_ImageStyle[feature.getProperties().Tipo]
  })
};

var vectorSource_O_abast_point = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_Red_abastecimiento_point)
});

global.Layer_O_abast_point = new VectorLayer({
  title: 'Layer_O_abast_point',
  name: '03 Red de abastecimiento',
  type: 'Capa específica',
  source: vectorSource_O_abast_point,
  style: styleFunction_O_abast_point,
  zIndex:9
});


var styleFunction_O_abast_line = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_O_abast_line]
  })
};


var vectorSource_O_abast_line = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_Red_abastecimiento_line)
});

global.Layer_O_abast_line = new VectorLayer({
  title: 'Layer_O_abast_line',
  name: 'F04 ' + Symbolyze_Layer_O_abast_line,
  type: 'Capa específica',
  source: vectorSource_O_abast_line,
  style: styleFunction_O_abast_line,
  zIndex:8
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_OE_05b = new LayerGroup({
  title:         'Map_OE_05b',
  name:       'Infraestructuras de abastecimiento de agua',
  type:         'Capa específica',
  opacity:    0.75,
	visible:     false,
  layers:[
    Layer_OE_ENP7,
    Layer_OE_Categorizacion_INFRA2,
    Layer_O_abast_point,
    Layer_O_abast_line
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE INFRAESTRUCTURA SANEAMIENTO

global.Layer_OE_Categorizacion_INFRA3 = new VectorLayer({
  title: 'Layer_OE_Categorizacion_INFRA3',
  name: '02 Clasificación y categorización del suelo',
  type: 'Capa específica',
  source: vectorSource_OE_Categorización_INFRA,
  style: styleFunction_OE_Categorizacion,
  zIndex:3
});

var styleFunction_O_sanea_point = function(feature, resolution) {
  return new  Style({
    image: Funct_ImageStyle[feature.getProperties().TIPO]
  })
};


var vectorSource_O_sanea_point = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_Red_saneamiento_point)
});

global.Layer_O_sanea_point = new VectorLayer({
  title: 'Layer_O_sanea_point',
  name: '03 Red de saneamiento',
  type: 'Capa específica',
  source: vectorSource_O_sanea_point,
  style: styleFunction_O_sanea_point,
  zIndex:9
});


var styleFunction_O_sanea_line = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().TIPO]
  })
};


var vectorSource_O_sanea_line = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_Red_saneamiento_line)
});

global.Layer_O_sanea_line = new VectorLayer({
  title: 'Layer_O_sanea_line',
  name: '03 Red de saneamiento',
  type: 'Capa específica',
  source: vectorSource_O_sanea_line,
  style: styleFunction_O_sanea_line,
  zIndex:8
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_OE_05c = new LayerGroup({
  title:         'Map_OE_05c',
  name:       'Infraestructuras de saneamiento y depuración de aguas',
  type:         'Capa específica',
  opacity:    0.75,
	visible:     false,
  layers:[
    Layer_OE_ENP8,
    Layer_OE_Categorizacion_INFRA3,
    Layer_O_sanea_point,
    Layer_O_sanea_line
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE CATEGORIZACIÓN ENP APROBADO
global.Layer_OE_Categorizacion_INFRA4 = new VectorLayer({
  title: 'Layer_OE_Categorizacion_INFRA4',
  name: '02 Clasificación y categorización del suelo',
  type: 'Capa específica',
  source: vectorSource_OE_Categorización_INFRA,
  style: styleFunction_OE_Categorizacion,
  zIndex:3
});

var styleFunction_O_elec_point = function(feature, resolution) {
  return new  Style({
    image: Funct_ImageStyle[feature.getProperties().Tipo]
  })
};


var vectorSource_O_elec_point = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_Red_elect_point)
});

global.Layer_O_elec_point = new VectorLayer({
  title: 'Layer_O_elec_point',
  name: '03 Infraestructura energética',
  type: 'Capa específica',
  source: vectorSource_O_elec_point,
  style: styleFunction_O_elec_point,
  zIndex:9
});


var styleFunction_O_elec_line = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().CLASIFICAC]
  })
};


var vectorSource_O_elec_line = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_Red_elect_line)
});

global.Layer_O_elec_line = new VectorLayer({
  title: 'Layer_O_elec_line',
  name: '03 Infraestructura energética',
  type: 'Capa específica',
  source: vectorSource_O_elec_line,
  style: styleFunction_O_elec_line,
  zIndex:8
});

var styleFunction_O_luminaria = function(feature, resolution) {
  return new  Style({
    image: Funct_ImageStyle[feature.getProperties().TIPO]
  })
};


var vectorSource_O_luminaria = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_Red_alumbrado)
});

global.Layer_O_luminaria = new VectorLayer({
  title: 'Layer_O_luminaria',
  name: '03 Infraestructura energética',
  type: 'Capa específica',
  source: vectorSource_O_luminaria,
  style: styleFunction_O_luminaria,
  zIndex:9
});

var styleFunction_O_teleco_point = function(feature, resolution) {
  return new  Style({
    image: Funct_ImageStyle[feature.getProperties().Tipo]
  })
};

var vectorSource_O_teleco_point = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_Red_teleco_point)
});

global.Layer_O_teleco_point = new VectorLayer({
  title: 'Layer_O_teleco_point',
  name: '04 Red de telecomunicaciones',
  type: 'Capa específica',
  source: vectorSource_O_teleco_point,
  style: styleFunction_O_teleco_point,
  zIndex:9
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_OE_05d = new LayerGroup({
  title:         'Map_OE_05d',
  name:       'Infraestructuras de energía eléctrica, alumbrado y telecomunicaciones',
  type:         'Capa específica',
  opacity:    0.75,
	visible:     false,
  layers:[
    Layer_OE_ENP9,
    Layer_OE_Categorizacion_INFRA4,
    Layer_O_elec_point,
    Layer_O_elec_line,
    Layer_O_luminaria,
    Layer_O_teleco_point
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE ZONIFICACIÓN ACÚSTICA
var vectorSource_OE_MODORD_contorno = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_MODORD)
});

var styleFunction_OE_MODORD_contorno = function(feature, resolution) { 
  var ParametrosLabel = Funct_Label["Layer_OE_Categorizacion"];

  return new  Style({
    fill: Funct_FillStyle[Symbolyze_Layer_OE_MODORD_contorno],
    stroke: Funct_StrokeStyle[Symbolyze_Layer_OE_MODORD_contorno],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15]),
  })
};

global.Layer_OE_MODORD_contorno = new VectorLayer({
  title: 'Layer_OE_MODORD_contorno',
  name: 'F01 ' + Symbolyze_Layer_OE_MODORD_contorno,
  type: 'Capa específica',
  source: vectorSource_OE_MODORD_contorno,
  style: styleFunction_OE_MODORD_contorno,
  zIndex:7
});

var styleFunction_OE_ZON_ACUST = function(feature, resolution) {
  var ValorSimbolizacion = Leyenda_OE_ZON_ACUST[feature.getProperties().First_TIPO][1]; 

  return new  Style({
    stroke: Funct_StrokeStyle[ValorSimbolizacion],
    fill: Funct_FillStyle[ValorSimbolizacion]
  })
};

var GeoJSON_O_MODORD_Filter_A = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_MODORD.features.length; i++){
  var feature =GeoJSON_O_MODORD.features[i];

  if (feature.properties["First_TIPO"] == "a") {
    GeoJSON_O_MODORD_Filter_A.features.push(feature);
  }
};

var vectorSource_OE_ZON_ACUST_A = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_MODORD_Filter_A)
});

global.Layer_OE_ZON_ACUST_A = new VectorLayer({
  title: 'Layer_OE_ZON_ACUST_A',
  name: '02 Zonificación acústica',
  type: 'Capa específica',
  source: vectorSource_OE_ZON_ACUST_A,
  style: styleFunction_OE_ZON_ACUST,
  zIndex:3
});

var GeoJSON_OE_SG_Filter_C = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_OE_SG.features.length; i++){
  var feature =GeoJSON_OE_SG.features[i];

  if (feature.properties["First_TIPO"] == "c") {
    GeoJSON_OE_SG_Filter_C.features.push(feature);
  }
};

var vectorSource_OE_ZON_ACUST_C = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_OE_SG_Filter_C)
});

global.Layer_OE_ZON_ACUST_C = new VectorLayer({
  title: 'Layer_OE_ZON_ACUST_C',
  name: '02 Zonificación acústica',
  type: 'Capa específica',
  source: vectorSource_OE_ZON_ACUST_C,
  style: styleFunction_OE_ZON_ACUST,
  zIndex:9
});

var GeoJSON_O_MODORD_Filter_G = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_MODORD.features.length; i++){
  var feature =GeoJSON_O_MODORD.features[i];

  if (feature.properties["First_TIPO"] == "g") {
    GeoJSON_O_MODORD_Filter_G.features.push(feature);
  }
};

var vectorSource_OE_ZON_ACUST_G = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_MODORD_Filter_G)
});

global.Layer_OE_ZON_ACUST_G = new VectorLayer({
  title: 'Layer_OE_ZON_ACUST_G',
  name: '02 Zonificación acústica',
  type: 'Capa específica',
  source: vectorSource_OE_ZON_ACUST_G,
  style: styleFunction_OE_ZON_ACUST,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_OE_06 = new LayerGroup({
  title:         'Map_OE_06',
  name:       'Zonificación acústica',
  type:         'Capa específica',
  opacity:    0.75,
	visible:     false,
  layers:[
    Layer_OE_ENP9,
    Layer_OE_MODORD_contorno,
    Layer_OE_ZON_ACUST_A,
    Layer_OE_ZON_ACUST_C,
    Layer_OE_ZON_ACUST_G
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE ALTERACIONES

var styleFunction_OE_ALT0 = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_ALT0_ZON"];

  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().ALT0],
    fill: Funct_FillStyle[feature.getProperties().ALT0],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

var vectorSource_OE_ALT0 = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_ALT0_ZON)
});

global.Layer_OE_PV = new VectorLayer({
  title: 'Layer_OE_PV',
  name: '02 Planeamiento Vigente',
  type: 'Capa específica',
  source: vectorSource_OE_ALT0,
  style: styleFunction_OE_ALT0,
  zIndex:3
});

var GeoJSON_O_Alteraciones_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_Alteraciones.features.length; i++){
  var feature =GeoJSON_O_Alteraciones.features[i];

  if (feature.properties["ALTERACION"] !== "-") {
    GeoJSON_O_Alteraciones_Filter.features.push(feature);
  }
};

var vectorSource_Alteraciones = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_Alteraciones_Filter)
});

var styleFunction_Alteraciones = function(feature, resolution) {
  var ValorSimbolizacion = Leyenda_Alteraciones[feature.getProperties().ALTERACION][1];

  return new  Style({
    stroke: Funct_StrokeStyle[ValorSimbolizacion],
    fill: Funct_FillStyle[ValorSimbolizacion]
  })
};

global.Layer_OE_Alteraciones = new VectorLayer({
  title: 'Layer_OE_Alteraciones',
  name: '03 Alteración',
  type: 'Capa específica',
  source: vectorSource_Alteraciones,
  style: styleFunction_Alteraciones,
  zIndex:9
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_OE_07 = new LayerGroup({
  title:         'Map_OE_07',
  name:       'Alteraciones',
  type:         'Capa específica',
  opacity:    0.75,
	visible:     false,
  layers:[
    Layer_OE_ENP10,
    Layer_OE_PV,
    Layer_OE_Alteraciones
  ]
});
////////////////////////////////////////////////////////////////////////