import GeoJSON from 'ol/format/GeoJSON.js';
import {Vector as VectorLayer, Group as LayerGroup} from 'ol/layer.js';
import {Vector as VectorSource} from 'ol/source.js';
import {Fill, Stroke, Style} from 'ol/style.js';



////////////////////////////////////////////////////////////////////////
//Función para capa de ENP
GeoJSON_ENP_FilterNulls  = FiltrarNulosGeoJson(GeoJSON_ENP, "FECHA_APRO")

var vectorSourceENP_DT = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_ENP_FilterNulls)
});

global.styleFunction_ENP_DT = function(feature, resolution) {
  var estilo;

  if(feature.getProperties().FECHA_APRO != null){
    estilo = new  Style({
      stroke: Funct_StrokeStyle[feature.getProperties().NOM_COMPLE],
      fill: Funct_FillStyle[feature.getProperties().NOM_COMPLE],
      text: createTextStyle(feature, resolution, 'none', feature.get("Tipo") + " " + feature.get("SITE_NAME") +  "\nAprobado en: " + feature.get("FECHA_APRO"), "Arial", 0.9, 1, "rgba(0, 0, 0, 1)", 1, 'rgba(255, 255, 255, 1)', 2, 1, 'rgba(255, 255, 255, 1)', "rgba(0, 0, 0, 0.75)", 0.2, [2,2,2,2], 0, 0),
      zIndex: Funct_ZIndexStyle[feature.getProperties().NOM_COMPLE]
    });
  };

  return estilo
};

global.Layer_D_ENP1 = new VectorLayer({
  title: 'Layer_D_ENP1',
  name: '01 Espacio Natural Protegido',
  type: 'Capa específica',
  source: vectorSourceENP_DT,
  style: styleFunction_ENP_DT,
  zIndex:8
});

global.Layer_D_ENP2 = new VectorLayer({
  title: 'Layer_D_ENP2',
  name: '01 Espacio Natural Protegido',
  type: 'Capa específica',
  source: vectorSourceENP_DT,
  style: styleFunction_ENP_DT,
  zIndex:8
});

global.Layer_D_ENP3 = new VectorLayer({
  title: 'Layer_D_ENP3',
  name: '01 Espacio Natural Protegido',
  type: 'Capa específica',
  source: vectorSourceENP_DT,
  style: styleFunction_ENP_DT,
  zIndex:8
});

global.Layer_D_ENP4 = new VectorLayer({
  title: 'Layer_D_ENP4',
  name: '01 Espacio Natural Protegido',
  type: 'Capa específica',
  source: vectorSourceENP_DT,
  style: styleFunction_ENP_DT,
  zIndex:8
});

global.Layer_D_ENP5 = new VectorLayer({
  title: 'Layer_D_ENP5',
  name: '01 Espacio Natural Protegido',
  type: 'Capa específica',
  source: vectorSourceENP_DT,
  style: styleFunction_ENP_DT,
  zIndex:8
});

global.Layer_D_ENP6 = new VectorLayer({
  title: 'Layer_D_ENP6',
  name: '01 Espacio Natural Protegido',
  type: 'Capa específica',
  source: vectorSourceENP_DT,
  style: styleFunction_ENP_DT,
  zIndex:8
});

global.Layer_D_ENP7 = new VectorLayer({
  title: 'Layer_D_ENP7',
  name: '01 Espacio Natural Protegido',
  type: 'Capa específica',
  source: vectorSourceENP_DT,
  style: styleFunction_ENP_DT,
  zIndex:8
});

global.Layer_D_ENP8 = new VectorLayer({
  title: 'Layer_D_ENP8',
  name: '01 Espacio Natural Protegido',
  type: 'Capa específica',
  source: vectorSourceENP_DT,
  style: styleFunction_ENP_DT,
  zIndex:8
});

global.Layer_D_ENP9 = new VectorLayer({
  title: 'Layer_D_ENP9',
  name: '01 Espacio Natural Protegido',
  type: 'Capa específica',
  source: vectorSourceENP_DT,
  style: styleFunction_ENP_DT,
  zIndex:8
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA UNIDADES TERRITORIALES BÁSICAS
var styleFunction_D_UTB = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().Nombre],
    fill: Funct_FillStyle[feature.getProperties().Nombre]
  })
};

var vectorSource_D_UTB = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_DT_UTB)
});

global.Layer_D_UTB = new VectorLayer({
  title: 'Layer_D_UTB',
  name: '02 Unidades Territoriales Básicas',
  type: 'Capa específica',
  source: vectorSource_D_UTB,
  style: styleFunction_D_UTB,
  zIndex:3
});

global.Map_DT_01 = new LayerGroup({
  title:    'Map_DT_01',
  name:     'Unidades Territoriales Básicas',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_D_UTB,
    Layer_D_ENP1
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE UNIDADES AMBIENTALES HOMOGÉNEAS
var styleFunction_D_UA = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_D_UA"];

  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().UA_NOM],
    fill: Funct_FillStyle[feature.getProperties().UA_NOM],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

var GeoJSON_D_UA1_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_D_UA.features.length; i++){
  var feature =GeoJSON_D_UA.features[i];

  if (feature.properties["UA_NOM"] == 'Barranco encajado  con predominancia natural' || feature.properties["UA_NOM"] == 'Vertiente acusada con predominancia natural' || feature.properties["UA_NOM"] == 'Vertiente acusada con presencia de HICs y cultivo disperso' || feature.properties["UA_NOM"] == 'Vertiente variable  con predominancia natural' || feature.properties["UA_NOM"] == 'Ladera volcánica con vegetación arbustiva y escaso uso agrícola' || feature.properties["UA_NOM"] == 'Vertiente acusada con predominancia natural y usos agrarios' || feature.properties["UA_NOM"] == 'Vertiente acusada con presencia de HICs' || feature.properties["UA_NOM"] == 'Vertiente variable  con predominancia agraria, natural y edificaciones dispersas') {
    GeoJSON_D_UA1_Filter.features.push(feature);
  }
};

var vectorSource_D_UA_1 = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_D_UA1_Filter)
});

global.Layer_D_UA1 = new VectorLayer({
  title: 'Layer_D_UA1',
  name: '02 Unidades con valor natural',
  type: 'Capa específica',
  source: vectorSource_D_UA_1,
  style: styleFunction_D_UA,
  zIndex:3
});

var GeoJSON_D_UA2_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_D_UA.features.length; i++){
  var feature =GeoJSON_D_UA.features[i];

  if (feature.properties["UA_NOM"] == 'Fondo de barranco con predominancia agraria  y edificaciones residenciales vinculadas' || feature.properties["UA_NOM"] == 'Llanura con predominancia agraria' || feature.properties["UA_NOM"] == 'Llanura con predominancia agraria  y edificaciones dispersas' || feature.properties["UA_NOM"] == 'Rampa con predominancia agraria y edificaciones residenciales vinculadas' || feature.properties["UA_NOM"] == 'Vertiente variable  con predominancia agraria' || feature.properties["UA_NOM"] == 'Vertiente variable con predominancia agraria y presencia de disperso' || feature.properties["UA_NOM"] == 'Vertiente variable  con predominancia natural, edificaciones dispersas y usos agrarios' || feature.properties["UA_NOM"] == 'Vertiente acusada  con predominancia agraria' || feature.properties["UA_NOM"] == 'Barranco  con predominancia agraria' || feature.properties["UA_NOM"] == 'Barranco  con predominancia agraria y edificaciones dispersas' ) {
    GeoJSON_D_UA2_Filter.features.push(feature);
  }
};

var vectorSource_D_UA_2 = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_D_UA2_Filter)
});

global.Layer_D_UA2 = new VectorLayer({
  title: 'Layer_D_UA2',
  name: '03 Unidades con aptitud productiva',
  type: 'Capa específica',
  source: vectorSource_D_UA_2,
  style: styleFunction_D_UA,
  zIndex:3
});

var GeoJSON_D_UA3_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_D_UA.features.length; i++){
  var feature =GeoJSON_D_UA.features[i];

  if (feature.properties["UA_NOM"] == 'Unidades que acogen actuaciones de interés general' ) {
    GeoJSON_D_UA3_Filter.features.push(feature);
  }
};

var vectorSource_D_UA_3 = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_D_UA3_Filter)
});

global.Layer_D_UA3 = new VectorLayer({
  title: 'Layer_D_UA3',
  name: '04 Unidades que acogen actuaciones de interés general',
  type: 'Capa específica',
  source: vectorSource_D_UA_3,
  style: styleFunction_D_UA,
  zIndex:3
});

var GeoJSON_D_UA4_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_D_UA.features.length; i++){
  var feature =GeoJSON_D_UA.features[i];

  if (feature.properties["UA_NOM"] == 'Asentamiento   Rural' || feature.properties["UA_NOM"] == 'Suelo  urbano' || feature.properties["UA_NOM"] == 'Área periurbana en fondo de barranco' || feature.properties["UA_NOM"] == 'Área periurbana en ladera') {
    GeoJSON_D_UA4_Filter.features.push(feature);
  }
};

var vectorSource_D_UA_4 = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_D_UA4_Filter)
});

global.Layer_D_UA4 = new VectorLayer({
  title: 'Layer_D_UA4',
  name: '05 Unidades que acogen suelos urbanos, urbanizables y asentamientos rurales',
  type: 'Capa específica',
  source: vectorSource_D_UA_4,
  style: styleFunction_D_UA,
  zIndex:3
});

global.Map_DT_02 = new LayerGroup({
  title:    'Map_DT_02',
  name:     'Unidades de Diagnóstico',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_D_UA1,
    Layer_D_UA2,
    Layer_D_UA3,
    Layer_D_UA4,
    Layer_D_ENP2
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE CAPACIDAD DE ACOGIDA
//Función para capa de Zonas de Especial Conservación
global.styleFunction_I_ZEC2 = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_I_ZEC"];

  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_I_ZEC],
    fill: Funct_FillStyle[Symbolyze_Layer_I_ZEC],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15]),
  })
};

global.vectorSource_I_ZEC2 = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_ZEC)
});

global.Layer_D_ZEC = new VectorLayer({
  title: 'Layer_D_ZEC',
  name: 'F03 ' + Symbolyze_Layer_I_ZEC,
  type: 'Capa específica',
  source: vectorSource_I_ZEC2,
  style: styleFunction_I_ZEC2,
  zIndex:8
});

//Función para capa de ZEPAs
global.styleFunction_I_ZEPA2 = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_I_ZEPA"];

  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_I_ZEPA],
    fill: Funct_FillStyle[Symbolyze_Layer_I_ZEPA],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15]),
  })
};


global.vectorSource_I_ZEPA2 = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_ZEPA)
});

global.Layer_D_ZEPA = new VectorLayer({
  title: 'Layer_D_ZEPA',
  name: 'F03 ' + Symbolyze_Layer_I_ZEPA,
  type: 'Capa específica',
  source: vectorSource_I_ZEPA2,
  style: styleFunction_I_ZEPA2,
  zIndex:8
});

//Función para capa de afección de carreteras
global.styleFunction_I_AFEC_CARR2 = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_I_AFEC_CARR],
    fill: Funct_FillStyle[Symbolyze_Layer_I_AFEC_CARR]
  })
};


global.vectorSource_I_AFEC_CARR2 = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_Afeccion_carreteras)
});

global.Layer_D_AFEC_CARR = new VectorLayer({
  title: 'Layer_D_AFEC_CARR',
  name: 'F05 ' + Symbolyze_Layer_I_AFEC_CARR,
  type: 'Capa específica',
  source: vectorSource_I_AFEC_CARR2,
  style: styleFunction_I_AFEC_CARR2,
  zIndex:9
});

//Función que capa de montes consorciados
global.styleFunction_I_Montes2 = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_I_Montes],
    fill: Funct_FillStyle[Symbolyze_Layer_I_Montes]
  })
};

global.vectorSource_I_Montes2 = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_Montes_consorciados)
});

global.Layer_D_Montes = new VectorLayer({
  title: 'Layer_D_Montes',
  name: 'F06 ' + Symbolyze_Layer_I_Montes,
  type: 'Capa específica',
  source: vectorSource_I_Montes2,
  style: styleFunction_I_Montes2,
  zIndex:7
});


//Función para la capa de Sistemas Territoriales Dispersos
var styleFunction_I_STD = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_I_STD],
    fill: Funct_FillStyle[Symbolyze_Layer_I_STD],
  })
};

var vectorSource_I_STD = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_STD)
});

global.Layer_I_STD = new VectorLayer({
  title: 'Layer_I_STD',
  name: 'F07 ' + Symbolyze_Layer_I_STD,
  type: 'Capa específica',
  source: vectorSource_I_STD,
  style: styleFunction_I_STD,
  zIndex:7
});


//Función para la capa de Sistemas Territoriales Dispersos
var styleFunction_I_PTE9 = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_I_PTE9],
    fill: Funct_FillStyle[Symbolyze_Layer_I_PTE9],
  })
};

var vectorSource_I_pte9 = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_PTE9)
});

global.Layer_I_PTE9 = new VectorLayer({
  title: 'Layer_I_PTE9',
  name: 'F08 ' + Symbolyze_Layer_I_PTE9,
  type: 'Capa específica',
  source: vectorSource_I_pte9,
  style: styleFunction_I_PTE9,
  zIndex:7
});

var styleFunction_D_UA_CAP_ACOG = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_D_UA"];

  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().CAP_ACOGID],
    fill: Funct_FillStyle[feature.getProperties().CAP_ACOGID],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

var GeoJSON_D_UA_Filter_Null = FiltrarNulosGeoJson(GeoJSON_D_UA, "CAP_ACOGID");

var vectorSource_D_UA_CAP_ACOG = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_D_UA_Filter_Null)
});

global.Layer_D_UA_CAP_ACOG = new VectorLayer({
  title: 'Layer_D_UA_CAP_ACOG',
  name: '09 Calidad para la conservación',
  type: 'Capa específica',
  source: vectorSource_D_UA_CAP_ACOG,
  style: styleFunction_D_UA_CAP_ACOG,
  zIndex:3
});

global.Map_DT_03 = new LayerGroup({
  title:    'Map_DT_03',
  name:     'Capacidad de acogida jurídica',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_D_UA_CAP_ACOG,
    Layer_D_ZEC,
    Layer_D_ZEPA,
    Layer_D_AFEC_CARR,
    Layer_D_Montes,
    Layer_I_STD,
    Layer_I_PTE9,
    Layer_D_ENP3
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE CALIDAD PARA LA CONSERVACIÓN
var styleFunction_D_UA_CAP_CONS = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_D_UA"];

  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().CALCONS__1],
    fill: Funct_FillStyle[feature.getProperties().CALCONS__1],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

var GeoJSON_D_UA_Filter_Null2 = FiltrarNulosGeoJson(GeoJSON_D_UA, "CALCONS__1");

var vectorSource_D_UA_CAP_CONS = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_D_UA_Filter_Null2)
});

global.Layer_D_UA_CAP_CONS = new VectorLayer({
  title: 'Layer_D_UA_CAP_CONS',
  name: '02 Calidad para la conservación',
  type: 'Capa específica',
  source: vectorSource_D_UA_CAP_CONS,
  style: styleFunction_D_UA_CAP_CONS,
  zIndex:3
});

global.Map_DT_04 = new LayerGroup({
  title:    'Map_DT_04',
  name:     'Calidad para la conservación',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_D_UA_CAP_CONS,
    Layer_D_ENP4
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE POTENCIALIDAD DEL SOPORTE TERRITORIO PARA USO NATURAL
var styleFunction_D_UA_POT_SOPOR_TERRI = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_D_UA"];

  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().PT_USO_N_1],
    fill: Funct_FillStyle[feature.getProperties().PT_USO_N_1],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

var GeoJSON_D_UA_Filter_Null3 = FiltrarNulosGeoJson(GeoJSON_D_UA, "PT_USO_N_1");

var vectorSource_D_UA_POT_SOPOR_TERRI = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_D_UA_Filter_Null3)
});

global.Layer_D_UA_POT_SOPOR_TERRI = new VectorLayer({
  title: 'Layer_D_UA_POT_SOPOR_TERRI',
  name: '02 Potencialidad del soporte territorio',
  type: 'Capa específica',
  source: vectorSource_D_UA_POT_SOPOR_TERRI,
  style: styleFunction_D_UA_POT_SOPOR_TERRI,
  zIndex:3
});

global.Map_DT_05 = new LayerGroup({
  title:    'Map_DT_05',
  name:     'Potencialidad del soporte territorio para uso natural',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_D_UA_POT_SOPOR_TERRI,
    Layer_D_ENP5
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE POTENCIALIDAD DEL SOPORTE TERRITORIO PARA ACTIVIDADES DEL SECTOR PRIMARIO
var styleFunction_D_UA_POT_SOPOR_TERRI_PRIM = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_D_UA"];

  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().PT_USO_A_1],
    fill: Funct_FillStyle[feature.getProperties().PT_USO_A_1],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

var GeoJSON_D_UA_Filter_Null4 = FiltrarNulosGeoJson(GeoJSON_D_UA, "PT_USO_A_1");

var vectorSource_D_UA_POT_SOPOR_TERRI_PRIM = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_D_UA_Filter_Null4)
});

global.Layer_D_UA_POT_SOPOR_TERRI_PRIM = new VectorLayer({
  title: 'Layer_D_UA_POT_SOPOR_TERRI_PRIM',
  name: '02 Potencialidad del soporte territorio',
  type: 'Capa específica',
  source: vectorSource_D_UA_POT_SOPOR_TERRI_PRIM,
  style: styleFunction_D_UA_POT_SOPOR_TERRI_PRIM,
  zIndex:3
});

global.Map_DT_06 = new LayerGroup({
  title:    'Map_DT_06',
  name:     'Potencialidad del soporte territorio para actividades del sector primario',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_D_UA_POT_SOPOR_TERRI_PRIM,
    Layer_D_ENP6
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE POTENCIALIDAD DEL SOPORTE TERRITORIO PARA PROCESOS DE URBANIZACIÓN
var styleFunction_D_UA_POT_SOPOR_TERRI_URBZ = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_D_UA"];

  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().PTUrb_DES],
    fill: Funct_FillStyle[feature.getProperties().PTUrb_DES],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

var GeoJSON_D_UA_Filter_Null5 = FiltrarNulosGeoJson(GeoJSON_D_UA, "PTUrb_DES");

var vectorSource_D_UA_POT_SOPOR_TERRI_URBZ = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_D_UA_Filter_Null5)
});

global.Layer_D_UA_POT_SOPOR_TERRI_URBZ = new VectorLayer({
  title: 'Layer_D_UA_POT_SOPOR_TERRI_URBZ',
  name: '02 Potencialidad del soporte territorio',
  type: 'Capa específica',
  source: vectorSource_D_UA_POT_SOPOR_TERRI_URBZ,
  style: styleFunction_D_UA_POT_SOPOR_TERRI_URBZ,
  zIndex:3
});

global.Map_DT_07 = new LayerGroup({
  title:    'Map_DT_07',
  name:     'Potencialidad del soporte territorio para procesos de urbanización',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_D_UA_POT_SOPOR_TERRI_URBZ,
    Layer_D_ENP7
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE POTENCIALIDAD URBANA
var styleFunction_D_UA_POT_SOPOR_TERRI_URB = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_D_UA"];

  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().PTU_DES],
    fill: Funct_FillStyle[feature.getProperties().PTU_DES],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

var GeoJSON_D_UA_Filter_Null6 = FiltrarNulosGeoJson(GeoJSON_D_UA, "PTU_DES");

var vectorSource_D_UA_POT_SOPOR_TERRI_URB = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_D_UA_Filter_Null6)
});

global.Layer_D_UA_POT_SOPOR_TERRI_URB = new VectorLayer({
  title: 'Layer_D_UA_POT_SOPOR_TERRI_URB',
  name: '02 Potencialidad urbana',
  type: 'Capa específica',
  source: vectorSource_D_UA_POT_SOPOR_TERRI_URB,
  style: styleFunction_D_UA_POT_SOPOR_TERRI_URB,
  zIndex:3
});

global.Map_DT_08 = new LayerGroup({
  title:    'Map_DT_08',
  name:     'Potencialidad urbana',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_D_UA_POT_SOPOR_TERRI_URB,
    Layer_D_ENP8
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE VALOR PATRIMONIAL
var styleFunction_D_UA_VAL_PATR = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_D_UA"];

  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().VALOR_PATR],
    fill: Funct_FillStyle[feature.getProperties().VALOR_PATR],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

GeoJSON_D_UA_Filter_FilterNull7  = FiltrarNulosGeoJson(GeoJSON_D_UA, "VALOR_PATR")

var vectorSource_D_UA_VAL_PATR  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_D_UA_Filter_FilterNull7)
});

global.Layer_D_UA_VAL_PATR = new VectorLayer({
  title: 'Layer_D_UA_VAL_PATR',
  name: '02 Valor patrimonial',
  type: 'Capa específica',
  source: vectorSource_D_UA_VAL_PATR,
  style: styleFunction_D_UA_VAL_PATR,
  zIndex:3
});

global.Map_DT_09 = new LayerGroup({
  title:    'Map_DT_09',
  name:     'Valor patrimonial',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_D_UA_VAL_PATR,
    Layer_D_ENP9
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE ÁREAS DE INTERÉS GEOLÓGICO Y GEOMORFOLÓGICO
var styleFunction_D_AIGG = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_D_AIGG"];

  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().LIT_NOMBRE + " AIGG"],
    fill: Funct_FillStyle[feature.getProperties().LIT_NOMBRE + " AIGG"],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

var vectorSource_D_AIGG = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_D_AIGG)
});

global.Layer_D_AIGG = new VectorLayer({
  title: 'Layer_D_AIGG',
  name: '01 Áreas de interés geológico y geomorfológico',
  type: 'Capa específica',
  source: vectorSource_D_AIGG,
  style: styleFunction_D_AIGG,
  zIndex:3
});

global.Map_DT_10 = new LayerGroup({
  title:    'Map_DT_10',
  name:     'Áreas de interés geológico y geomorfológico',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_D_AIGG
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE ÁREAS DE INTERÉS NATURAL Y DE BIODIVERSIDAD

var styleFunction_D_AINB = function(feature, resolution) {
  var ValorSimbolizacion = Leyenda_AINB[feature.getProperties().COD_AINB][1];
  var ParametrosLabel = Funct_Label["Layer_D_AINB"];

  return new  Style({
    stroke: Funct_StrokeStyle[ValorSimbolizacion],
    fill: Funct_FillStyle[ValorSimbolizacion],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15]),
    zIndex: Funct_ZIndexStyle[ValorSimbolizacion],
  })
};


var vectorSource_D_AINB = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_D_AINB)
});

global.Layer_D_AINB = new VectorLayer({
  title: 'Layer_D_AINB',
  name: '01 Áreas de interés natural y de biodiversidad',
  type: 'Capa específica',
  source: vectorSource_D_AINB,
  style: styleFunction_D_AINB,
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_DT_11 = new LayerGroup({
  title:    'Map_DT_11',
  name:     'Áreas de interés natural y de biodiversidad',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_D_AINB
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE ÁREAS DE INTERÉS NATURAL Y DE BIODIVERSIDAD

var styleFunction_D_AIPC= function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().AIPC_DES],
    fill: Funct_FillStyle[feature.getProperties().AIPC_DES]
  })
};


var vectorSource_D_AIPC = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_D_AIPC)
});

global.Layer_D_AIPC = new VectorLayer({
  title: 'Layer_D_AIPC',
  name: '01 Áreas de interés de patrimonio cultural',
  type: 'Capa específica',
  source: vectorSource_D_AIPC,
  style: styleFunction_D_AIPC,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_DT_12 = new LayerGroup({
  title:    'Map_DT_12',
  name:     'Áreas de interés de patrimonio cultural',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_D_AIPC
  ]
});
////////////////////////////////////////////////////////////////////////
